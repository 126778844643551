
import Vue from 'vue'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue-cookies';

import modules from './modules/module';
import widgets from './widgets/widget';
import workers from './workers/worker';

Vue.prototype.$axios = axios.create({
  baseURL: 'https://vevanta.ru/api/',
})

Vue.config.productionTip = false
Vue.use(VueCookies);

let components = {
  ...modules,
  ...widgets,
  ...workers
}


let vm = new Vue({
  store,
  el: '#app',
  components
}).$mount()
